import { Controller } from '@hotwired/stimulus';
import $ from 'jquery';
import Select2 from "select2"

export default class extends Controller {


  connect() {
    const selectElement = this.element;
    const maxElement = selectElement.attributes.maximumselectionlength;

    if (selectElement) {
        new Select2(selectElement);
        $(selectElement).select2({
          maximumSelectionLength: maxElement ? maxElement.value : ''
        });

        $(selectElement).on('select2:select', function () {
            let event = new Event('change', { bubbles: true }) 
            this.dispatchEvent(event);
          });        
    }
  }

}