import { Controller } from '@hotwired/stimulus';
export default class extends Controller {

  static values = {
    url: String
  }
  
  connect() {

  }
  
  getTownList(event) {
    
    const dropdown = event.target;
    const targetdropdown = document.getElementById('identity_sheet_cityPlaceOfResidence');

    
    
    const stateCodevalue = dropdown.options[dropdown.selectedIndex].dataset.stateCode;
    
    const data = new FormData();
    data.append('state-code', stateCodevalue);
    fetch(this.urlValue, {
      method: "POST",
      body: data,
    })
      .then((response) => response.json())
      .then((data) => {
        document.getElementById('identity_sheet_zipcode').value= '';
        targetdropdown.innerHTML = '<option></option>';
        data.forEach(item => {
          let newOption = new Option(item.label,item.id);
          newOption.dataset.zipCode = item.zipCode
          targetdropdown.add(newOption, undefined);
          
        });
        console.log(data);
      })
      .catch((error) => {
        // Gestion des erreurs
        console.log(error)
      });    
  }
  
  setZipCode(event){
    const targetZipCode = document.getElementById('identity_sheet_zipcode');
    const zipCodevalue = event.target.options[event.target.selectedIndex].dataset.zipCode;
    console.log(zipCodevalue);
    targetZipCode.value = zipCodevalue
  }

}