import { Controller } from '@hotwired/stimulus';
export default class extends Controller {

  static values = {
    targetChildrensClass: String,
  }
  connect() {
    const triggerValue = this.element.dataset.childrensTriggerValue
    this.element.addEventListener('change', function (e) {
      const input = this.element
      const currentValues = Array.from(this.selectedOptions).map(option => option.text);
      const inputClass = this.classList
      Object.entries(JSON.parse(triggerValue)).forEach(([key, val]) => {
        const childrens = document.querySelectorAll('.' + key);
        val.forEach( triggerVal =>  {

          if (inputClass.contains('excludedValue')) {
            if (currentValues.includes(triggerVal)) {
              childrens.forEach(item => {
                item.classList.add('d-none');
              });            
            } else {
              childrens.forEach(item => {
                item.classList.remove('d-none');
              });
  //            return;
            }
          } else {
            if (currentValues.includes(triggerVal)) {
              childrens.forEach(item => {
                item.classList.remove('d-none');
              });            
            } else {
              childrens.forEach(item => {
                item.classList.add('d-none');
              });
  //            return;
            }
          }

        })
      });
    });
    this.element.dispatchEvent(new Event('change'));
  }

}