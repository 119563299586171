import * as Turbo from '@hotwired/turbo'; // eslint-disable-line no-unused-vars
import { Application } from '@hotwired/stimulus';
import * as Tabler from '@tabler/core/dist/js/tabler.min.js';
import FormCollectionController from './controllers/form_collection_controller.js';
import FieldWithChildrenController from './controllers/field_with_children_controller.js';
import SelectDepartmentController from './controllers/select_department_controller.js';
import Select2Controller from './controllers/select2_controller.js';

const application = Application.start();
application.register('form-collection', FormCollectionController);
application.register('field-with-children', FieldWithChildrenController);
application.register('select-department', SelectDepartmentController);
application.register('select2', Select2Controller);

