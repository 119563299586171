import { Controller } from '@hotwired/stimulus';

export default class extends Controller {

  static targets = ["collectionContainer"]

  static values = {
    index: Number,
    prototype: String,
  }

  connect() {
    // document
    //         .querySelectorAll('div.collectionBloc')
    //         .forEach((tag) => {
    //           this.addTagFormDeleteLink(tag)
    //         })

    const singleFile = document.querySelector('.singleFile .btn');

    document
            .querySelectorAll('.btn-delete-collection-element')
            .forEach((btn) => {
              btn.addEventListener('click', (e) => {
                if (singleFile != null) {
                  this.indexValue--;
                }
                e.target.parentNode.parentNode.remove();
              })
            })

  }

  addCollectionElement(e) {
    const singleFile = document.querySelector('.singleFile .btn');
    if(singleFile == null){
      const item = document.createElement('div');
      item.innerHTML = this.prototypeValue.replace(/__name__/g, this.indexValue);
      this.collectionContainerTarget.appendChild(item);
      item
              .querySelector('.btn-delete-collection-element')
              .addEventListener('click', (e) => {
                e.target.parentNode.parentNode.remove();
              })
      this.indexValue++;
    } 
    
    if (singleFile != null && this.indexValue == 0) {
      const item = document.createElement('div');
      item.innerHTML = this.prototypeValue.replace(/__name__/g, this.indexValue);
      this.collectionContainerTarget.appendChild(item);
      this.indexValue++;

      item
      .querySelector('.btn-delete-collection-element')
      .addEventListener('click', (e) => {
        e.target.parentNode.parentNode.remove();
        this.indexValue--;
      })

    }

  }

  addTagFormDeleteLink = (item) => {
    const removeFormButton = document.createElement('button');
    removeFormButton.innerText = "Supprimer";
    removeFormButton.classList.add("btn");
    removeFormButton.classList.add("btn-danger");
    removeFormButton.classList.add("ms-5");
    removeFormButton.classList.add("mb-5");

    item.append(removeFormButton);

    removeFormButton.addEventListener('click', (e) => {
      e.preventDefault();
      // remove the li for the tag form
      item.remove();
    });
  }

}